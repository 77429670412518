import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#2196f3",
                primaryDark: "#6f4e37",
                secondary: "#091e30",
                accent: "#fcdd00",
                error: "#f44336",
                warning: "#ffeb3b",
                info: "#2196f3",
                success: "#01AE53",
            },
        },
    },
});
