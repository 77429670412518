import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import("../layout/Public"),
        children: [
            {
                path: "",
                name: "home",
                component: () => import("../pages/home/Home"),
            },
            {
                path: "/about",
                name: "about",
                component: () => import("../pages/about/About"),
            },
            {
                path: "/gallery",
                name: "gallery",
                component: () => import("../pages/gallery/Gallery"),
            },
            {
                path: "/service",
                name: "service",
                component: () => import("../pages/service/Service"),
            },
            {
                path: "/product",
                name: "product",
                component: () => import("../pages/product/Product"),
            },
            {
                path: "/event",
                name: "event",
                component: () => import("../pages/events/Events.vue"),
            },
            {
                path: "/contact",
                name: "contact",
                component: () => import("../pages/contacts/Contact"),
            },
            {
                path: "/order",
                name: "order",
                component: () => import("../pages/contacts/Order"),
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // scrolling behavior
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        return { x: 0, y: 0 }
    },

})

export default router